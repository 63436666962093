import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  confirmPasswordReset,
  applyActionCode,
  checkActionCode,
  sendPasswordResetEmail,
} from 'firebase/auth';

import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FB_API_KEY,
  authDomain: process.env.GATSBY_FB_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FB_DB_URL,
  projectId: process.env.GATSBY_FB_PID,
  storageBucket: process.env.GATSBY_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FB_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FB_APP_ID,
  measurementId: process.env.GATSBY_FB_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const resetPassword = (
  oobCode,
  newPassword,
  confirmPassword,
  setError,
  setLoading,
  setSuccess
) => {
  if (newPassword.length < 8) {
    setError({
      message: 'Passwords must contain at least 8 characters',
      field: 'password',
    });
    return;
  } else if (newPassword !== confirmPassword) {
    setError({
      message: 'Passwords do not match',
      field: 'confirm-password',
    });
    return;
  }

  confirmPasswordReset(auth, oobCode, newPassword)
    .then(() => {
      setLoading(false);
      setSuccess(true);
    })
    .catch(() => {
      setError({
        message: 'There was an issue resetting your password',
      });
      setLoading(false);
    });
};

const recoverEmail = async (oobCode) => {
  try {
    const info = await checkActionCode(auth, oobCode);
    const restoredEmail = info && info['data'] && info['data']['email'];
    if (restoredEmail) {
      await applyActionCode(auth, oobCode);
      return { success: true, email: restoredEmail };
    }
  } catch (e) {
    return { success: false, errorCode: e && e.code };
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (e) {
    console.log(e)
    return false;
  }
};

const PasswordReset = ({ oobCode }) => {
  const [newPassword, setNewPassword] = useState(''),
    [confirmPassword, setConfirmPassword] = useState(''),
    [error, setError] = useState({}),
    [loading, setLoading] = useState(false),
    [success, setSuccess] = useState(false),
    [showPassword, setShowPassword] = useState(false),
    [showConfirmPassword, setShowConfirmPassword] = useState(false),
    handleClickShowPassword = () => setShowPassword((show) => !show),
    handleClickShowConfirmPassword = () =>
      setShowConfirmPassword((show) => !show),
    handleMouseDownPassword = (e) => e.preventDefault();

  return (
    <section style={{ width: '100%' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Password Reset - Moneycl App</title>
        <link rel="canonical" href="https://moneycl.app/account" />
      </Helmet>
      <Paper
        elevation={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 20,
          maxWidth: 320,
          width: '70%',
          padding: '25px 40px',
          margin: 'auto',
          marginTop: 15,
          borderRadius: 15,
        }}
      >
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 30,
            margin: 0,
          }}
        >
          Password reset
        </h1>
        {!success && (
          <>
            <span style={{ textAlign: 'center' }}>Set a new password</span>
            <FormControl
              error={
                (error.field === 'password' || !error.field) && error.message
              }
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => setNewPassword(e.target.value)}
                label="New password"
                aria-describedby="password-error-text"
              />
              {error.field === 'password' && (
                <FormHelperText id="password-error-text">
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              error={error.field !== 'password' && error.message}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirm password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => setConfirmPassword(e.target.value)}
                label="Confirm password"
                aria-describedby="confirm-password-error-text"
              />
              {error.field === 'confirm-password' && (
                <FormHelperText id="confirm-password-error-text">
                  {error.message}
                </FormHelperText>
              )}
              {!error.field && error.message && (
                <FormHelperText id="confirm-password-error-text">
                  {error.message}
                </FormHelperText>
              )}
            </FormControl>
            <span style={{ textAlign: 'center' }}>
              Passwords must contain at least 8 characters
            </span>
            <LoadingButton
              variant="contained"
              loading={loading}
              loadingPosition="end"
              endIcon={<SendIcon />}
              onClick={() =>
                resetPassword(
                  oobCode,
                  newPassword,
                  confirmPassword,
                  setError,
                  setLoading,
                  setSuccess
                )
              }
            >
              Set password
            </LoadingButton>
          </>
        )}
        {success && (
          <>
            <span role="alert" style={{ textAlign: 'center', color: 'green' }}>
              Password reset was successful!
            </span>
          </>
        )}
      </Paper>
    </section>
  );
};

const emailVerification = (oobCode, setError, setLoading, setSuccess) => {
  applyActionCode(auth, oobCode)
    .then(() => {
      setLoading(false);
      setSuccess(true);
    })
    .catch((err) => {
      console.log(err);
      setError(true);
      setLoading(false);
    });
};

const VerifyEmail = ({ oobCode }) => {
  const [error, setError] = useState(false),
    [loading, setLoading] = useState(true),
    [success, setSuccess] = useState(false);

  useEffect(() => {
    emailVerification(oobCode, setError, setLoading, setSuccess);
  }, []);

  return (
    <section style={{ width: '100%' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Email Verification - Moneycl App</title>
        <link rel="canonical" href="https://moneycl.app/account" />
      </Helmet>
      <Paper
        elevation={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 20,
          maxWidth: 320,
          width: '70%',
          padding: '25px 40px',
          margin: 'auto',
          marginTop: 15,
          borderRadius: 15,
        }}
      >
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 30,
            margin: 0,
          }}
        >
          Email verification
        </h1>
        {!success && loading && (
          <>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center', color: 'green' }}>
              Please wait as we verify your email.
            </span>
            <CircularProgress />
          </>
        )}
        {!success && !loading && (
          <>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center', color: 'red' }}>
              There was an issue when attempting to verify your email.
            </span>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center' }}>
              Your email may already have been verified. Please check your
              account settings in the Moneycl app.
            </span>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center' }}>
              If not, please feel free to contact us.
            </span>
            <Button variant="contained" href="/contact">
              Contact us
            </Button>
            <Button
              variant="contained"
              href={process.env.GATSBY_MONEYCL_EMAIL_VERIFIED_DL}
            >
              Return to Moneycl app
            </Button>
          </>
        )}
        {success && (
          <>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center', color: 'green' }}>
              Email has been verified!
            </span>
            <Button
              variant="contained"
              href={process.env.GATSBY_MONEYCL_EMAIL_VERIFIED_DL}
            >
              Return to Moneycl app
            </Button>
          </>
        )}
      </Paper>
    </section>
  );
};

const RecoverEmail = ({ oobCode }) => {
  const [error, setError] = useState(false),
    [errorCode, setErrorCode] = useState('UNKNOWN'),
    [loading, setLoading] = useState(false),
    [email, setEmail] = useState(''),
    [success, setSuccess] = useState(false),
    [passwordSuccess, setPasswordSuccess] = useState(false),
    [passwordLoading, setPasswordLoading] = useState(false),
    [passwordError, setPasswordError] = useState(false);

  return (
    <section style={{ width: '100%' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reset Email - Moneycl App</title>
        <link rel="canonical" href="https://moneycl.app/account" />
      </Helmet>
      <Paper
        elevation={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 20,
          maxWidth: 400,
          minWidth: 240,
          width: '70%',
          padding: '25px 40px',
          margin: 'auto',
          marginTop: 15,
          borderRadius: 15,
        }}
      >
        <h1
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 30,
            margin: 0,
          }}
        >
          Email reset
        </h1>
        {!success && (
          <>
            <span style={{ textAlign: 'center' }}>
              If your Moneycl sign-in email was recently changed and you did not
              request it, you can request to reset it here.
            </span>
            <LoadingButton
              variant="contained"
              loading={loading}
              loadingPosition="end"
              endIcon={<SendIcon />}
              onClick={async () => {
                setLoading(true);
                setError(false);
                const { success, email, errorCode } = await recoverEmail(
                  oobCode
                );
                if (!success) {
                  setError(true);
                  errorCode && setErrorCode(errorCode);
                } else {
                  setSuccess(true);
                  setEmail(email);
                }
                setLoading(false);
              }}
            >
              {error ? 'Try again' : 'Reset email'}
            </LoadingButton>
          </>
        )}
        {error && !loading && (
          <>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center', color: 'red' }}>
              There was an issue when attempting to reset your Moneycl sign-in
              to your original email.
            </span>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center' }}>
              If the issue persists, feel free to contact us and give us the
              following error code:
              <br />
              {'(code: ' + errorCode + ')'}
            </span>
            <Button variant="contained" href="/contact">
              Contact us
            </Button>
            <Button
              variant="contained"
              href={process.env.GATSBY_MONEYCL_EMAIL_VERIFIED_DL}
            >
              Return to Moneycl app
            </Button>
          </>
        )}
        {success && (
          <>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center', color: 'green' }}>
              Your sign-in email has been reset to {email}!
            </span>
            <span role="alert" aria-live="polite" style={{ textAlign: 'center' }}>
              It may be a good idea to reset your password as well. You can do
              so by having an email sent to you with a link to reset your
              password.
            </span>
            {!passwordSuccess && (
              <LoadingButton
                variant="contained"
                loading={passwordLoading}
                loadingPosition="end"
                endIcon={<SendIcon />}
                onClick={async () => {
                  setPasswordError(false)
                  setPasswordLoading(true);
                  setError(false);
                  const success = await sendPasswordReset(email);
                  setPasswordSuccess(success);
                  if (!success) {
                    setPasswordError(true)
                  }
                  setPasswordLoading(false);
                }}
              >
                {passwordError ? 'Try again' : 'Send password reset'}
              </LoadingButton>
            )}
            {passwordSuccess && (
              <>
                <span
                  role="alert"
                  aria-live="polite"
                  style={{ textAlign: 'center', color: 'green' }}
                >
                  Password reset email has been sent!
                </span>
              </>
            )}
            {passwordError && (
              <>
                <span role="alert" aria-live="polite" style={{ textAlign: 'center', color: 'red' }}>
                  There was an issue when attempting to reset your Moneycl sign-in
                  to your original email. If the issue persists, feel free to contact us.
                </span>
                <Button variant="contained" href="/contact">
                  Contact us
                </Button>
              </>
            )}
            <Button
              variant="contained"
              href={process.env.GATSBY_MONEYCL_EMAIL_VERIFIED_DL}
            >
              Return to Moneycl app
            </Button>
          </>
        )}
      </Paper>
    </section>
  );
};

const Account = ({ location }) => {
  const urlParams = new URLSearchParams(location.search),
    oobCode = urlParams.get('oobCode'),
    mode = urlParams.get('mode');

  useEffect(() => {
    if (
      mode !== 'resetPassword' &&
      mode !== 'verifyEmail' &&
      mode !== 'recoverEmail'
    ) {
      navigate('/');
    }
  }, []);

  if (mode === 'resetPassword') {
    return <PasswordReset oobCode={oobCode} />;
  } else if (mode === 'verifyEmail') {
    return <VerifyEmail oobCode={oobCode} />;
  } else if (mode === 'recoverEmail') {
    return <RecoverEmail oobCode={oobCode} />;
  }

  return <></>;
};

export default Account;
